import React from 'react'
import PropTypes from 'prop-types'

import CssBaseline from '@material-ui/core/CssBaseline'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%'
  },
  main: {
    // padding: theme.spacing(5)
  },
  footer: {
    textAlign: 'center',
    padding: theme.spacing(4),
  }
}));

const Layout = ({ children }) => {
  const classes = useStyles()
  return (
    <Container className={classes.root}>
    <Grid container direction='column' alignItems='center' justify='space-between' className={classes.root} wrap='nowrap'>
      <CssBaseline/>
      <Grid item component='header'></Grid>
      <Grid item component='main' className={classes.main}>{children}</Grid>
      <Grid item component='footer' className={classes.footer}>
        <Typography variant='body2'>
          Copyright © 2010-{new Date().getFullYear()} Pablo Tejada, All Right Reserved.
        </Typography>
      </Grid>
    </Grid>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
